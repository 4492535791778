


import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SymbolValueField from "./SymbolValueField/SymbolValueField";
import {getDashboardGetOneUserPointData} from "../../../asyncActions/dashboard/getDashboardGetOneUserPointData";
import {getSymbolInfo} from "../../../asyncActions/vtu/getSymbolInfo";
import NewVTUSelectSymbol from "./NewVTUSelectSymbol/NewVTUSelectSymbol";
import {updateUserFreeCoins} from "../../../asyncActions/vtu/updateUserFreeCoins";
import {getUserCoinsVolumeAfterDate} from "../../../asyncActions/vtu/getUserCoinsVolumeAfterDate";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';




const NewVTUDialogButton: React.FC = () => {
    const [open, setOpen] = useState(false); // Состояние для контроля открытого состояния диалога


    const [symbol, setSymbol] = useState<string>("");
    const [quantity, setQuantity] = useState<string>('0.0');
    const [buyPrice, setBuyPrice] = useState<string>('0.0');
    const [sellPrice, setSellPrice] = useState<string>('0.0');
    const [buyFirst, setBuyFirst] = useState<boolean>(true);

    const [value, setValue] = useState<string>('0'); // Значение для поля ввода
    const [isValidQuantity, setIsValidQuantity] = useState<boolean>(true); // Статус валидации


    const handleSymbol = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setSymbol(e.target.value)}
    }

    const handleClickOpen = () => {
        setOpen(true); // Открыть диалог
    };

    const handleClose = () => {
        setOpen(false); // Закрыть диалог
    };

    const handleRegistrationClick = async () => {
        // await addNewTeam(userName, email, telegram, itAges, commandName, streamName, projectAbout, store.referer)
        setSymbol('MASKUSDT')

        // setOpen(false); // Закрыть диалог
        // toast.success('Вы успешно зарегистрировали команду ' + commandName, {
        //     autoClose: 10000,  // Уведомление закроется через 30 секунд
        //     position: "top-right"
        // });
    }

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const getUserCoinsVolumeAfterDateWithDelay = async (updated_at: string) => {
        await delay(200);  // Ждем 200 миллисекунд
        const coinsVolume = await getUserCoinsVolumeAfterDate(updated_at)
        return coinsVolume;
    };

    useEffect(() => {
        const fetchData = async () => {
            let localSymbol: string = ""
            if (symbol !== undefined){localSymbol = symbol}
            if (localSymbol != ""){
                const data = await getSymbolInfo(localSymbol);
                console.log(data)

                const updated_at = await updateUserFreeCoins()
                console.log(updated_at.updated_at)

                const coinsVolume = await getUserCoinsVolumeAfterDateWithDelay(updated_at.updated_at)
                console.log(coinsVolume)

            }
        }
        fetchData()
    },[symbol]);

    return(
        <>
            <Button
                variant="contained"    // Использует стиль заполненной кнопки
                size="large"           // Увеличивает размер кнопки
                onClick={handleClickOpen}
                sx={{
                    width: '200px',      // Задаем ширину кнопки
                    height: '60px',      // Задаем высоту кнопки
                    fontSize: '1.2rem',  // Увеличиваем размер шрифта
                    borderRadius: '8px', // Скругляем углы
                }}
            >
                New VTU
            </Button>
            {/*<ToastContainer />*/}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Creating new VTU</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Заполните все поля
                    </DialogContentText>
                    <NewVTUSelectSymbol symbol={symbol} setSymbol={setSymbol} />

                    <SymbolValueField label={'Quantity'} quantity={quantity} setQuantity={setQuantity} />
                    <SymbolValueField label={'Buy price'} quantity={buyPrice} setQuantity={setBuyPrice} />
                    <SymbolValueField label={'Sell price'} quantity={sellPrice} setQuantity={setSellPrice} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRegistrationClick} color="primary">
                        BUY
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewVTUDialogButton;