import React, {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import {Box, IconButton, TextField} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface SymbolValueFieldProps {
    label: string;
    quantity: string; // Тип для значения
    setQuantity: React.Dispatch<React.SetStateAction<string>>; // Тип для функции обновления состояния
}

const SymbolValueField: React.FC<SymbolValueFieldProps> = ({label, quantity, setQuantity}) => {
    const [isValidQuantity, setIsValidQuantity] = useState<boolean>(true); // Статус валидации
    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // Регулярное выражение для проверки формата числа с плавающей запятой (например, 0.123 или 123)
        const regex = /^[0-9]*([.,][0-9]{0,3})?$/; // Максимум 3 знаков после запятой

        if (regex.test(inputValue) || inputValue === '') {
            setQuantity(inputValue);
            setIsValidQuantity(true);
        } else {
            setIsValidQuantity(false);
        }
    }

    const increaseQuantity = () => {
        const newValue = parseFloat(quantity) + 0.001;
        setIsValidQuantity(true);
        setQuantity(newValue.toFixed(3)); // Ограничиваем до 3 знака после запятой
    };

    const decreaseQuantity = () => {
        const newValue = parseFloat(quantity) - 0.001;
        if (newValue >= 0.0) {
            setIsValidQuantity(true);
            setQuantity(newValue.toFixed(3)); // Ограничиваем до 3 знака после запятой
        }
    };
    return(
        <>
            <TextField
                sx={{
                    marginTop: '6px',
                    marginBottom: '6px',
                }}
                label={label}
                variant="outlined"
                value={quantity}
                onChange={handleQuantity}
                error={!isValidQuantity}
                helperText={!isValidQuantity && "Введите правильное число с максимум 3 знаками после запятой"}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>, // Добавляем знак валюты
                    endAdornment: (
                        <InputAdornment position="end" sx={{ pr: 0 }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-end', // Выравнивание по правому краю
                                borderLeft: '1px solid #ccc', // Добавление обводки слева от кнопок
                                '& button': {
                                    marginBottom: '-10px', // Меньший отступ между кнопками
                                },
                            }}>
                                <IconButton
                                    onClick={increaseQuantity}
                                    size="small"
                                    sx={{
                                        borderRadius: '4px',
                                        marginBottom: '0px', // Меньший отступ между кнопками
                                        '&:hover': {
                                            color: '#000000', // Цвет фона при наведении
                                            borderColor: '#888', // Изменение цвета обводки при наведении
                                        },
                                    }}
                                >
                                    <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                    onClick={decreaseQuantity}
                                    size="small"
                                    sx={{
                                        borderRadius: '4px',
                                        marginBottom: '0px', // Меньший отступ между кнопками
                                        '&:hover': {
                                            color: '#000000', // Цвет фона при наведении
                                            borderColor: '#888', // Изменение цвета обводки при наведении
                                        },
                                    }}
                                >
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </Box>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    )
}

export default SymbolValueField;