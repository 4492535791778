
import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box} from "@mui/system";
import { styled } from '@mui/material/styles';
import {useAppSelector} from "../../../../hooks/redux";
import {getAllAvaliableSymbols} from "../../../../asyncActions/userSettings/getAllAvaliableSymbols";


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export interface IUserProfileSelectSymbolsProps{
    symbol: string,
    setSymbol: Dispatch<SetStateAction<string>>,
}

const NewVTUSelectSymbol: React.FC<IUserProfileSelectSymbolsProps> = ({symbol, setSymbol}) => {
    const [availableSybmolsArray, setAvailableSymbolsArray] = useState<string[]>([]);

    const handleAddElement = (newSymbol: string | null) => {
        //Добавлять только в тех случаях, если такого элемента в массиве ещё нет
        if (newSymbol != null){
            setSymbol(newSymbol)
            console.log(newSymbol)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await  getAllAvaliableSymbols();
            setAvailableSymbolsArray(data)
            return data;
        }
        fetchData()
    },[]);

    return(
        <>
            <div style={{marginTop: "16px"}}>
                <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300 }}
                    options={availableSybmolsArray}
                    onChange={(event: any, newValue: string | null) => {
                        handleAddElement(newValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option: string) => option}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option}
                        </Box>
                    )}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a symbol"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
        </>
    )
}

export default NewVTUSelectSymbol;