import {backendUrl, siteOrigin} from "../../globalConfig";

export const updateUserFreeCoins = async () => {
    return await fetch(backendUrl+'/vtu/update_user_free_coins', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
}
