import {backendUrl, siteOrigin} from "../../globalConfig";

export const getSymbolInfo = async (symbol: string) => {
    const params = new URLSearchParams({
        symbol: symbol
    }).toString()
    return await fetch(backendUrl+'/vtu/get_symbol_info?' + params, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
}
