import {backendUrl, siteOrigin} from "../../globalConfig";

export const getUserCoinsVolumeAfterDate = async (after_date: string) => {
    const params = new URLSearchParams({
        after_date: after_date
    }).toString()
    return await fetch(backendUrl+'/vtu/get_user_coins_volume_after_date?' + params, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
}
